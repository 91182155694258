import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link } from "gatsby"

export default class Headerr extends Component {
  state = {}
  handleToggleMenu = () => this.setState({ sidebarOpened: true })
  handleToggleCloseMenu = () => this.setState({ sidebarOpened: false })

  render() {
    const { sidebarOpened } = this.state
    return (
      <header className="header-global">
        <nav
          id="navbar-main"
          className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-dark navbar-theme-dark headroom headroom--not-bottom headroom--not-top headroom--pinned"
        >
          <div className="container position-relative">
            <Link className="navbar-brand mr-lg-5" to="/">
              <img
                className="navbar-brand-dark"
                src="/brand/light.svg"
                alt="Mauto.ma"
              />{" "}
              <img
                className="navbar-brand-light"
                src="/brand/dark.svg"
                alt="Mauto.ma"
              />
            </Link>
            <div
              className={
                "navbar-collapse collapse " + (sidebarOpened ? "show" : "")
              }
              id="navbar_global"
            >
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link className="navbar-brand mr-lg-5" to="/">
                      <img
                        className="navbar-brand-light"
                        src="/brand/dark.svg"
                        alt="Mauto.ma"
                      />
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <a
                      onClick={this.handleToggleCloseMenu}
                      href="#navbar_global"
                      role="button"
                      className="fas fa-times"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></a>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/marques-voitures">
                    <span className="nav-link-inner-text">
                      Toutes les marques
                    </span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/actualites">
                    <span className="nav-link-inner-text">Actualité</span>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/voitures-en-promo-maroc">
                    <span className="nav-link-inner-text">
                      Voitures en promo
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-flex align-items-center">
              <button
                onClick={this.handleToggleMenu}
                className="navbar-toggler ml-2"
                type="button"
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

Headerr.propTypes = {
  siteTitle: PropTypes.string,
}

Headerr.defaultProps = {
  siteTitle: ``,
}
