import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"

import Headerr from "./header"

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }

        allTaxonomyTermMarques {
          edges {
            node {
              id
              path {
                alias
              }
              name
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: "description",
              content: "Mauto.ma : references des voitures neuves au maroc",
            },
            {
              name: "keywords",
              content: "mauto, voitures, neuves, prix voitures",
            },
          ]}
        />
        <Headerr siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>

        <footer className="footer py-6 bg-primary text-white">
          <div className="container">
            <div className="">
              <div className="row">
                <div className="col-md-2">
                  <a className="footer-brand mr-lg-5" href="/">
                    <img src="/brand/light.svg" alt="Mauto.ma logo" />
                  </a>
                  <ul className="social-buttons mb-5 mb-lg-0">
                    <li>
                      <a
                        href="https://www.facebook.com/voituresneuve"
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-lg btn-link btn-facebook text-white"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Facebook"
                      >
                        Facebook
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-md-3 mb-5 mb-lg-0">
                  <h6>Marques voitures neuves</h6>
                  <ul className="footer-links mt-2">
                    {data.allTaxonomyTermMarques.edges
                      .slice(0, 6)
                      .map(({ node: marque }) => (
                        <li key={marque.id}>
                          <Link to={marque.path.alias}>
                            {marque.name} maroc prix
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="col-6 col-md-3 mb-5 mb-lg-0">
                  <h6>Marques voitures neuves</h6>
                  <ul className="footer-links mt-2">
                    {data.allTaxonomyTermMarques.edges
                      .slice(6, 12)
                      .map(({ node: marque }) => (
                        <li key={marque.id}>
                          <Link to={marque.path.alias}>
                            {marque.name} maroc prix
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="col-6 col-md-3 mb-5 mb-lg-0">
                  <h6>Marques voitures neuves</h6>
                  <ul className="footer-links mt-2">
                    {data.allTaxonomyTermMarques.edges
                      .slice(12, 18)
                      .map(({ node: marque }) => (
                        <li key={marque.id}>
                          <Link to={marque.path.alias}>
                            {marque.name} maroc prix
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="copyright mt-4 text-center">
              2020{" "}
              <a href="https://mauto.ma" target="_blank" rel="noreferrer">
                Mauto.ma
              </a>
              . All rights reserved.
            </div>
          </div>
        </footer>

        <script
          data-ad-client="ca-pub-0366082310128425"
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
